define(
  "widgets/cr-advanced-search",
  ["framework/globalUtils/koNodePreprocessor", "framework/koMapper/mapper", "app/util/elasticLookupMapper"],
  function(widgets, mapper, ElasticLookupMapper) {
    widgets.addElementHandler({
      type: "cr-advanced-search",
      process: function($node) {
        var select2String = "crAdvancedSearch: {" + $node.attr("options") + "}";
        return $("<span></span>").append(widgets.mapAttributes($node, $('<input type="hidden" data-bind="' + select2String + '" />')));
      }
    });

    ko.bindingHandlers.crAdvancedSearch = {
      init: function(element, valueAccessor, ab, viewModel) {
        var vaUnrolled = valueAccessor(),
          $element = $(element),
          searchVm = vaUnrolled.searchVm,
          suppressText = vaUnrolled.suppressTextOption,
          dynamicPlaceholder = vaUnrolled.dynamicPlaceholder,
          getDisableTextSearch = vaUnrolled.searchVm.disableTextSearch || (() => false);

        var select2Options = {
          placeholder: ko.unwrap(dynamicPlaceholder) || vaUnrolled.placeholder || "",
          query: vaUnrolled.textOnly ? textOnlyQuery : query,
          minimumInputLength: vaUnrolled.minimumInputLength || 3,
          formatResult: formatAdvSearchResultOption,
          dropdownCss: vaUnrolled.dropdownCss || undefined
        };

        var subscriptions = [];

        $element.select2(select2Options);
        $element.on("change", function(o, b, c) {
          var obj = o.added,
            vmProp = getAdvSearchPacket(obj.type).vm;

          if (!obj) return;

          if (searchVm._collectionArr.indexOf(vmProp) > -1) {
            searchVm[vmProp + "Collection"].push(cr.viewModelFactory.commonViewModels.multiSeachInstanceVm.createFromResponse(obj));
            searchVm.updateHashCollection(vmProp);
          } else {
            var isText = ["text", "textActive"].indexOf(obj.type) > -1;
            searchVm.setFilterItem(vmProp, isText ? obj.name : obj.id);
          }

          $element.select2("val", "");
        });

        if (ko.isSubscribable(dynamicPlaceholder)) {
          subscriptions.push(
            dynamicPlaceholder.subscribe(function(newValue) {
              $element
                .select2("container")
                .find(".select2-chosen")
                .text(newValue);
            })
          );
        }

        var queryTimeout;

        function textOnlyQuery(packet) {
          packet.callback({ results: [{ id: -1, name: packet.term, type: "text" }] });
        }

        function query(packet) {
          var channelId = vaUnrolled.call.split(".")[0],
            action = vaUnrolled.call.split(".")[1];
          var call = vaUnrolled.call;
          !(suppressText || getDisableTextSearch()) && packet.callback({ results: [{ id: -1, name: packet.term, type: "text" }] });

          clearTimeout(queryTimeout);
          queryTimeout = setTimeout(function() {
            if ($element.data("select2").search.val() !== packet.term) return;

            !(suppressText || getDisableTextSearch()) && packet.callback({ results: [{ id: -1, name: packet.term, type: "textActive" }] });

            var request = ko.toJS(searchVm);
            delete request.allLabels;

            let promise = null;
            const mapElastic = r => {
              r.items = r.results;

              r.items.forEach((val, idx) => {
                val.Id = val.key;
                val.Type = val.resultType;
                val.Name = val.displayName;
              });

              return r;
            };

            if (ElasticLookupMapper.default.hasElasticEquivalent(call)) {
              // translate to elastic
              const apiTier = ElasticLookupMapper.default.mapMidTierToApi(call);
              const lookupOptions = apiTier.source;

              promise = Promise.resolve(cr.transport.api.get(`search/lookups?query=${encodeURIComponent(packet.term)}&${lookupOptions}`)).then(
                mapElastic
              );
            } else {
              promise = Promise.resolve(cr.transport.successRequest(channelId, action, { search: packet.term, searchVm: request }, r => r));
            }

            promise.then(function(r) {
              var staticResults = [],
                regex = new RegExp(packet.term, "i");

              searchVm._staticSearchItems.forEach(function(ssi) {
                var termMatch = Linq.From(ssi.terms || []).FirstOrDefault(null, function(al) {
                  return regex.test(al);
                });
                if (termMatch) {
                  staticResults.push({ type: ssi.option, id: ssi.vmValue });
                }
              });

              for (var i = r.items.length - 1; i >= 0; i--) {
                var result = r.items[i],
                  mapItem = getAdvSearchPacket(result.Type || result.type);
                if (!mapItem) continue;

                if (searchVm._collectionArr.indexOf(mapItem.vm) > -1) {
                  if (
                    searchVm[mapItem.vm + "Collection"]().filter(function(item) {
                      return item.id() == (result.id || result.Id);
                    }).length
                  ) {
                    r.items.splice(i, 1);
                  }
                } else {
                  if (searchVm[mapItem.vm]() == (result.id || result.Id)) {
                    r.items.splice(i, 1);
                  }
                }
              }

              var results = [];
              !(suppressText || getDisableTextSearch()) && results.push({ id: -1, name: packet.term, type: "text" });

              packet.callback({
                results: results.concat(staticResults).concat(
                  r.items.map(function(o) {
                    return {
                      id: o.Id || o.id,
                      name: o.Name,
                      type: o.Type
                    };
                  })
                )
              });
            });
          }, 500);
        }

        function formatAdvSearchResultOption(o) {
          var packet = getAdvSearchPacket(o.type);
          if (!packet) return o.name;
          return typeof packet.option === "function" ? packet.option(o) : o.name;
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
          subscriptions.forEach(function(sub) {
            sub.dispose();
          });
          $element.select2("destroy");
          $element = null;
        });
      }
    };

    function isOptionValid(searchVm, result) {
      var mapItem = getAdvSearchPacket(result.Type || result.type);
      if (!mapItem) return false;

      if (searchVm._collectionArr.indexOf(mapItem.vm) > -1) {
        if (searchVm[mapItem.vm + "Collection"]().filter(item => item.id() == (result.id || result.Id)).length) {
          return false;
        }
      } else {
        if (searchVm[mapItem.vm]() == (result.id || result.Id)) {
          return false;
        }
      }
      return true;
    }

    function onSelect(searchVm, obj) {
      var vmProp = getAdvSearchPacket(obj.type).vm;

      if (!obj) return;

      if (searchVm._collectionArr.indexOf(vmProp) > -1) {
        searchVm[vmProp + "Collection"].push(cr.viewModelFactory.commonViewModels.multiSeachInstanceVm.createFromResponse(obj));
        searchVm.updateHashCollection(vmProp);
      } else {
        var isText = ["text", "textActive"].indexOf(obj.type) > -1;
        searchVm.setFilterItem(vmProp, isText ? obj.name : obj.id);
      }
    }

    function getAdvSearchPacket(type) {
      var advancedSearchResultsMap = cr.viewModelFactory.plugins.advancedSearch.advancedSearchResultsMap,
        result = advancedSearchResultsMap[type];

      if (result) return result;

      for (var key in advancedSearchResultsMap) {
        if (key.toLowerCase() === type.toString().toLowerCase()) {
          return advancedSearchResultsMap[key];
        }
      }
    }

    return { onSelect, isOptionValid };
  }
);
